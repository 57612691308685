import { ReportCountTotalEnum, ReportCountTotalResponse, ReportCountTotal } from '@/views/ReportStatistics/RapportAantallenOhw/RapportAantallenOhw';
import { organizationUuid } from '@/models/Organization';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Rpc } from '@/models/Rpc';
import { ReportStatus } from '@/support/ReportStatus';
// eslint-disable-next-line import/no-duplicates
import { StatisticsColumn, StatisticsOptions } from '@/components/week-statistics-view/WeekStatisticsView';
// eslint-disable-next-line import/no-duplicates
import WeekStatisticsView from '@/components/week-statistics-view/WeekStatisticsView.vue';
import RapportAantallenTabs, { RapportAantallenTab } from '@/views/ReportStatistics/RapportAantallen/RapportAantallenTabs/RapportAantallenTabs';
import { ReportTypes } from '@/support/ReportTypes';
import { WorkflowEnum } from '@/components/filters/work-flow-filter/WorkFlowFilter';

@Component<RapportAantallenPerStatus>({
  components: {
    WeekStatisticsView,
  },
})
export default class RapportAantallenPerStatus extends Vue {
  @Prop({ default: 'regulier' })
  protected activeTab!: string;

  @Prop({ default: () => ({}) })
  protected filters!: Filters;

  @Prop()
  protected organization!: string;

  @Prop({ default: false })
  protected showSpecialTab!: boolean;

  @Prop({ default: () => [] })
  protected tabs!: RapportAantallenTab[];

  protected currentFilters: Filters = {
    special: null,
  };

  protected isLoading = true;

  protected tabStatus: {[key: string]: string[]} = {
    regulier: [
      ReportStatus.PLANNING_ORGANIZATION,
      ReportStatus.OPNAME_IN_PROGRESS,
      ReportStatus.OPNAME_REJECTED,
      ReportStatus.OPNAME_DONE,
      ReportStatus.IN_PROGRESS,
      ReportStatus.SUPERVISOR,
      ReportStatus.REJECTED,
      ReportStatus.SUBMITTED,
      ReportStatus.CHECKING,
      ReportStatus.VELDWERK_REJECTED,
      ReportStatus.STRUCTURAL_ENGINEER_REJECTED,
    ],
    ves: [
      ReportStatus.OPNAME_IN_PROGRESS,
      ReportStatus.OPNAME_REJECTED,
      ReportStatus.CHECKING,
    ],
    special: [
      ReportStatus.PLANNING_ORGANIZATION,
      ReportStatus.OPNAME_IN_PROGRESS,
      ReportStatus.OPNAME_REJECTED,
      ReportStatus.OPNAME_DONE,
      ReportStatus.IN_PROGRESS,
      ReportStatus.REJECTED,
      ReportStatus.SUBMITTED,
      ReportStatus.CHECKING,
      ReportStatus.VELDWERK_REJECTED,
      ReportStatus.STRUCTURAL_ENGINEER_REJECTED,
    ],
    nader_advies: [
      ReportStatus.ZIENSWIJZE_IN_PROGRESS,
      ReportStatus.ZIENSWIJZE_REJECTED,
      ReportStatus.ZIENSWIJZE_CHECKING,
      ReportStatus.ZIENSWIJZE_VELDWERK_REJECTED,
    ],
    bezwaar: [
      ReportStatus.OBJECTION_PENDING_ASSIGNMENT,
      ReportStatus.OBJECTION_IN_PROGRESS,
      ReportStatus.OBJECTION_REJECTED,
      ReportStatus.OBJECTION_CHECKING,
      ReportStatus.OBJECTION_VELDWERK_REJECTED,
    ],
    ambtelijk_bezwaar: [
      ReportStatus.OBJECTION_PENDING_ASSIGNMENT,
      ReportStatus.OBJECTION_IN_PROGRESS,
      ReportStatus.OBJECTION_REJECTED,
      ReportStatus.OBJECTION_CHECKING,
      ReportStatus.OBJECTION_VELDWERK_REJECTED,
    ],
    stuwmeer: [
      ReportStatus.SMR_IN_PROGRESS,
      ReportStatus.SMR_REJECTED,
      ReportStatus.SMR_CHECKING,
      ReportStatus.SMR_VELDWERK_REJECTED,
    ],
  }

  protected totals: ModifiedManagementReports[] = [];

  protected statisticsOptions: StatisticsOptions = {
    totalColumnName: 'reports_total',
  };

  protected mounted(): void {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.currentFilters = { ...this.currentFilters, ...this.filters };
    this.isLoading = true;
    this.$emit('is-loading', this.isLoading);
    await this.getAmounts();
    this.isLoading = false;
    this.$emit('is-loading', this.isLoading);
  }

  protected async getAmounts(): Promise<void> {
    if (! this.activeTab) {
      return;
    }

    const activeTab = this.tabs.find((tab: RapportAantallenTab) => tab.key === this.activeTab);
    if (activeTab) {
      this.currentFilters.workflow = activeTab.workflow;
    }

    if (this.showSpecialTab) {
      if (this.activeTab === 'special' || this.activeTab === 'total') {
        this.currentFilters.special = true;
      } else if (this.activeTab === 'regulier') {
        this.currentFilters.special = false;
      } else {
        this.currentFilters.special = null;
      }
    }

    if (this.activeTab === 'total') {
      this.fetchTotals();
      return;
    }
    this.currentFilters.status = this.tabStatus[this.activeTab];

    const payload = {
      signature: 'management-reports:bureau-report-count:status',
      body: this.currentFilters,
    };

    const response = await new Rpc()
      .dmz(this.organization)
      .rpcPost(payload, false)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
    if (! response) {
      return;
    }

    this.totals = [];
    (response.data as ManagementReports[]).forEach((data: ManagementReports) => {
      if (data) {
        const item: ModifiedManagementReports = { ...data, ...{} };

        // Create the links to the reports overview, with the filters depending on the tab/filter

        item.links = {};

        for (let filter of ['total', 'external', 'planned', 'replanned', 'todo']) {
          let link = new URLSearchParams({
            status: item.key as string,
            ... (this.organization !== organizationUuid.img)
              ? {
                organizations: this.organization
              }
              : {},
            filter: filter
          });

          if (['ves'].includes(this.activeTab)) {
            link.set('types', ReportTypes.VES_NULMETING);
          }

          if (['regulier'].includes(this.activeTab)) {
            link.set('is_special', '0');
            link.set('exclude_types', ReportTypes.VES_NULMETING);
          }

          if (['special'].includes(this.activeTab)) {
            link.set('filter', `${filter}_special`);
          }

          const workflow = RapportAantallenTabs.getWorkflowForTab(this.tabs, this.activeTab);
          if (workflow) {
            link.set('workflow', workflow);
          }

          item.links[filter] = `/reports?${link.toString()}`;
        }

        this.totals.push(item);
      }
    });
  }

  protected async fetchTotals(): Promise<void> {
    let split_special = '';

    if (this.showSpecialTab) {
      split_special = 'show_special_row';
    } else {
      if (this.currentFilters.special) {
        split_special = 'only_specials';
      }

      if (this.currentFilters.special === false) {
        split_special = 'no_specials';
      }

      if (this.currentFilters.special === null) {
        split_special = 'combined';
      }
    }

    const payload = {
      signature: 'management-reports:bureau-report-count-total:status',
      body: {
        split_special,
        departments: this.filters.departments,
        types: this.filters.types,
      },
    };

    const response = await new Rpc()
      .dmz(this.organization)
      .rpcPost(payload, false)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
    if (! response) {
      return;
    }

    this.totals = [];

    this.calculateTotals((response.data as ReportCountTotalResponse));
  }

  protected calculateTotals(totals: ReportCountTotalResponse): void {
    const rows = this.tabs // Use order and names of tabs to define order and name of rows
      .filter((tab: RapportAantallenTab) => tab.key !== 'total') // Remove key 'totals'
      .filter((tab: RapportAantallenTab) => tab.key !== 'special' || this.showSpecialTab); // Remove key 'special' if tab not available

    const sumColumn = (columnKey: ReportCountTotalEnum, rows: ReportCountTotal[]) => rows.reduce((accumulator, row) => accumulator + row[columnKey], 0);

    this.totals = rows.map((tab: RapportAantallenTab) => ({
      external: sumColumn(ReportCountTotalEnum.EXTERNAL, totals[tab.key] || []),
      key: tab.key,
      planned: sumColumn(ReportCountTotalEnum.PLANNED, totals[tab.key] || []),
      replanned: sumColumn(ReportCountTotalEnum.REPLANNED, totals[tab.key] || []),
      todo: sumColumn(ReportCountTotalEnum.TODO, totals[tab.key] || []),
      total: sumColumn(ReportCountTotalEnum.TOTAL, totals[tab.key] || []),
      status: tab.name,
    }));
  }

  protected get columns(): StatisticsColumn[] {
    return [
      {
        key: 'status',
        name: 'Status',
        class: 'xs2 md2 lg2',
      },
      {
        key: 'external',
        name: 'Externe<br>Partij',
        class: 'xs2 text-right',
        format: () => 'Externe<br>Partij',
      },
      {
        key: 'planned',
        name: 'Toekomstig<br>gepland',
        class: 'xs2 text-right',
      },
      {
        key: 'replanned',
        name: 'Vervolg<br>Afspraak',
        class: 'xs2 text-right',
      },
      {
        key: 'todo',
        name: 'Onderhanden<br>werk',
        class: 'xs2 text-right',
      },
      {
        key: 'total',
        name: 'Totaal',
        class: 'xs2 text-right',
      },
    ];
  }

  protected emitBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rapport aantallen OHW' },
        ],
      });
  }

  @Watch('filters', { deep: true })
  protected filtersChanged(): void {
    this.initialize();
  }

  @Watch('organization')
  protected organizationChanged(): void {
    this.initialize();
  }

  @Watch('activeTab')
  protected activeTabChanged(): void {
    this.initialize();
  }
}

interface Filters {
  special?: boolean | null;
  status?: string[];
  departments?: string[];
  types?: string[];
  workflow?: WorkflowEnum | null;
}

interface ManagementReports {
  external: number;
  key: string;
  planned: number;
  replanned: number;
  status: string;
  todo: number;
  total: number;
}

interface ModifiedManagementReports extends ManagementReports {
  links?: {[key: string]: string};
}
