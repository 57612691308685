import { render, staticRenderFns } from "./RapportAantallenPerStatus.vue?vue&type=template&id=2ddb1e68&"
import script from "./RapportAantallenPerStatus.ts?vue&type=script&lang=ts&"
export * from "./RapportAantallenPerStatus.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ddb1e68')) {
      api.createRecord('2ddb1e68', component.options)
    } else {
      api.reload('2ddb1e68', component.options)
    }
    module.hot.accept("./RapportAantallenPerStatus.vue?vue&type=template&id=2ddb1e68&", function () {
      api.rerender('2ddb1e68', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ReportStatistics/RapportAantallen/RapportAantallenPerStatus/RapportAantallenPerStatus.vue"
export default component.exports