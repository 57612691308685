import { WorkflowEnum, WorkflowLabels } from '@/components/filters/work-flow-filter/WorkFlowFilter';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component<RapportAantallenTabs>({})
export default class RapportAantallenTabs extends Vue {
  @Prop({ default: false })
  protected showSpecialTab!: boolean;

  protected activeTab = 'regulier';

  protected onTabClick(tab: string): void {
    this.activeTab = tab;
    this.$emit('tabChange', this.activeTab);
  }

  protected get tabs(): RapportAantallenTab[] {
    return [
      {
        name: WorkflowLabels[WorkflowEnum.REGULIER],
        key: 'regulier',
        workflow: WorkflowEnum.REGULIER,
      },
      {
        name: 'Specials',
        key: 'special',
        workflow: WorkflowEnum.REGULIER,
        visible: this.showSpecialTab,
      },
      {
        name: WorkflowLabels[WorkflowEnum.NADER_ADVIES],
        key: 'nader_advies',
        workflow: WorkflowEnum.NADER_ADVIES,
      },
      {
        name: WorkflowLabels[WorkflowEnum.AMBTELIJK_BEZWAAR],
        key: 'ambtelijk_bezwaar',
        workflow: WorkflowEnum.AMBTELIJK_BEZWAAR,
      },
      {
        name: WorkflowLabels[WorkflowEnum.BEZWAAR],
        key: 'bezwaar',
        workflow: WorkflowEnum.BEZWAAR,
      },
      {
        name: WorkflowLabels[WorkflowEnum.SMR],
        key: 'stuwmeer',
        workflow: WorkflowEnum.SMR,
      },
      {
        name: WorkflowLabels[WorkflowEnum.VES],
        key: 'ves',
        workflow: WorkflowEnum.VES,
      },
      {
        name: 'Totaal',
        key: 'total',
        workflow: null,
      },
    ];
  }

  public static getWorkflowForTab(tabs: RapportAantallenTab[], activeTab: string) : WorkflowEnum | null
  {
    for (const {key, workflow} of tabs) {
      if (key === activeTab) {
        return workflow;
      }
    }

    return null;
  }
}

export interface RapportAantallenTab {
  name: string;
  key: string;
  workflow: WorkflowEnum | null;
  visible?: boolean;
}
